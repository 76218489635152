<template>
  <z-tabs
    v-model="tabIndex"
    pills
    class="h-100"
    content-class="pt-4 h-100 pb-5"
  >
    <z-tab title="Resumen">
      <resume-tab :service="service" @reload="$emit('reload')" />
    </z-tab>
    <z-tab title="Registros" class="h-100">
      <logs-tab
        :service="service"
        class="h-100"
        @cancel-schedule-service="$emit('show-service-modal')"
        @update:show="$emit('update:show', $event)"
      />
    </z-tab>
    <z-tab title="Adicionales" class="h-100">
      <additional-options-tab
        :additional-options="additional"
        :additional-hours-request="additionalHoursRequest"
        class="h-100"
      />
    </z-tab>
    <z-tab v-if="showMap && service != null" lazy title="Mapa">
      <map-tab :service="service" :center-map="centerMap" />
    </z-tab>
    <z-tab v-if="hasExternal" title="Identificadores externos" class="h-100">
      <external-tab :numeric-id="numericId" :additional="additional" />
    </z-tab>
    <z-tab v-if="hasIncidences" title="Incidencias">
      <incidences-tab :incidences="incidences" />
    </z-tab>
    <z-tab v-if="invoicesTab" title="Error de facturación">
      <invoice-error :error="invoiceError" />
    </z-tab>
    <z-tab v-if="hasSignature" title="Firmas">
      <signatures-tab :signatures="signatures" />
    </z-tab>
    <z-tab v-if="hasDeliveryProof" title="Evidencia de entrega">
      <delivery-proof-tab :delivery-proof="deliveryProofList" />
    </z-tab>
  </z-tabs>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";
import AdditionalOptionsTab from "./ServiceDetailTabsAdditionalOptions";
import ExternalTab from "./ServiceDetailTabsExternal";
import ResumeTab from "./ServiceDetailTabsResume/ResumeTab";
import IncidencesTab from "./ServiceDetailTabsIncidences";
import InvoiceError from "./ServiceDetailTabsInvoiceError";
import LogsTab from "@/app/components/ServiceLogs/ServiceLogs";
import SignaturesTab from "./ServiceDetailTabsSignatures";
import DeliveryProofTab from "./ServiceDetailTabsDeliveryProof";
import IncidencesConstants from "@/constants/services/incidences";
import ServiceStatus from "@/constants/services/status";
import ServiceBillingStatus from "@/constants/services/billing-status";
import ServiceType from "@zubut/common/src/constants/services/type";
import DestinationReceiver from "@/constants/services/destination-receiver";
import ZTabs from "@zubut/common/src/components/ZTabs";
import ZTab from "@zubut/common/src/components/ZTab";

const MapTab = () => import("./ServiceDetailTabsMap");

export default {
  name: "ServiceDetailTabs",

  components: {
    AdditionalOptionsTab,
    ExternalTab,
    IncidencesTab,
    InvoiceError,
    LogsTab,
    SignaturesTab,
    DeliveryProofTab,
    ResumeTab,
    MapTab,
    ZTabs,
    ZTab
  },

  props: {
    arrived: {
      type: [String, Object],
      default: ""
    },
    delivered: {
      type: [String, Object],
      default: ""
    },
    service: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      tabIndex: 0,
      centerMap: null
    };
  },

  computed: {
    additional() {
      if (this.service && this.service.additional) {
        return this.service.additional;
      }
      return {};
    },

    additionalHoursRequest() {
      if (this.service && this.service.additionalHoursRequest) {
        return this.service.additionalHoursRequest;
      }
      return {};
    },

    numericId() {
      if (this.service && this.service.numericId != null) {
        return this.service.numericId;
      }
      return null;
    },

    hasRatings() {
      return (
        this.service.ratings &&
        (this.service.ratings.driver || this.service.ratings.client)
      );
    },

    invoiceError() {
      return this.service?.invoice?.satError;
    },

    invoicesTab() {
      return (
        this.service.billingStatus === ServiceBillingStatus.NUM_INVOICE_ERROR &&
        this.service?.invoice?.satError
      );
    },

    checkPoints() {
      return this.service.resume.checkPoints;
    },

    destinations() {
      if (
        !ServiceStatus.CANCELLED_STATUS.includes(this.service.status) &&
        this.service.resume &&
        this.service.resume.signatures
      ) {
        const destinations = [...this.service.destinations];
        this.service.resume.signatures.forEach((signature, index) => {
          destinations[index].signed = formatISO(signature.time, "h:mm a");
        });
        return destinations;
      }
      return this.service.destinations;
    },

    hasDeliveryProof() {
      if (this.service?.destinations?.some(dest => dest.deliveryProof)) {
        return true;
      }
      return false;
    },

    hasSignature() {
      return (
        this.service.resume &&
        this.service.resume.checkPoints &&
        this.service.resume.checkPoints.some(ckp => ckp.signature)
      );
    },

    incidences() {
      const validIncidences = [
        IncidencesConstants.NUM_INCIDENCE_SERVICES,
        IncidencesConstants.NUM_INCIDENCE_RETURN_BY_DRIVER
      ];
      if (this.service.incidences && this.service.incidences.length > 0) {
        return this.service.incidences.filter(incidence =>
          validIncidences.includes(incidence.incidenceId)
        );
      }
      return [];
    },

    serviceStops() {
      if (this.service.resume && this.service.resume.checkPoints) {
        return this.service.resume.checkPoints.map(item => ({
          order: item.order,
          arrived: formatISO(item.arrived, "h:mm a"),
          delivered: formatISO(item.delivered, "h:mm a")
        }));
      }
      return [];
    },

    signatures() {
      const signatures = [];
      this.destinations.forEach((dest, index) => {
        if (this.checkPoints[index] && this.checkPoints[index].signature) {
          const signature = { name: dest.name };
          signature.time = formatISO(
            this.checkPoints[index].delivered,
            "d 'de' MMM, u hh:mm a"
          );
          signature.image = this.checkPoints[index].signature;
          signatures.push(signature);
        }
      });
      return signatures;
    },

    deliveryProofList() {
      const proofList = [];

      this.destinations.forEach((destination, index) => {
        if (destination.deliveryProof) {
          proofList.push({
            receiverType:
              destination.deliveryProof?.receiverType ??
              DestinationReceiver.OWNER,
            receiverName: destination.deliveryProof?.receiverName ?? "Unknown",
            destinationName: destination.name ?? `Destino ${index++}.`,
            imageUrl: destination.deliveryProof?.imageUrl ?? ""
          });
        }
      });

      return proofList;
    },

    hasIncidences() {
      return this.incidences.length > 0;
    },

    hasExternal() {
      return (
        this.service &&
        this.service.additional &&
        (this.service.additional.externalId ||
          this.service.additional.numericId)
      );
    },

    isPackageService() {
      return this.service.type === ServiceType.NUM_PACKAGE_DELIVERY;
    },

    showMap() {
      return !this.isPackageService;
    }
  },

  watch: {
    tabIndex(newVal) {
      // Different to map tap
      if (newVal !== 3) {
        this.centerMap = null;
      }
    }
  },

  mounted() {
    this.$root.$on("open-incidence", this.handleOpenIncidence);
  },

  beforeDestroy() {
    this.$root.$off("open-incidence", this.handleOpenIncidence);
  },

  methods: {
    handleOpenIncidence(position) {
      // Index 3 for Map
      this.tabIndex = 3;
      this.centerMap = position;
    }
  }
};
</script>
