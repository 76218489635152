import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Transactions");

const Transactions = Object.assign(
  {},
  {
    relation: "",

    createInvoice(id) {
      return loopback.post(`/Transactions/${id}/createInvoice`);
    },

    listing({ filter = {}, where = {} }) {
      filter.order = filter.order || "createdAt DESC";
      return loopback.get("/Transactions/listing", {
        params: { filter, where }
      });
    },

    linkedTransaction(id, data) {
      return loopback.post(`/Transactions/${id}/linkedTransaction`, data);
    },

    editTransaction(id, data) {
      return loopback.post(`/Transactions/${id}/editTransaction`, data);
    }
  },
  modelObject
);

export default Transactions;
