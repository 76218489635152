<template>
  <div class="z-service-normal-detail">
    <div>
      <div
        class="z-total-container d-flex align-items-center justify-content-between font-weight-semi-bold"
      >
        <div class="font-weight-bold">
          Total
        </div>
        <div>{{ totalFormatted }} {{ currency }}</div>
      </div>
    </div>
    <b-list-group flush class="border-top mt-3">
      <b-list-group-item v-if="haveAdditionalHours">
        <div class="font-weight-bold">
          Cargo por tiempo extra
        </div>
        <div>{{ getAdditionalHoursCost }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold">
          {{ messageForSubtotal }}
        </div>
        <div>{{ subtTotalFormatted }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold">{{ taxMessage }} (16%)</div>
        <div>{{ taxFormatted }}</div>
      </b-list-group-item>
      <slot name="custom-items"></slot>
      <b-list-group-item class="mt-5">
        <div class="font-weight-bold">
          Kilómetros recorridos
        </div>
        <div>{{ distanceFormatted }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold">
          Tiempo de servicio
        </div>
        <div>{{ timeFormatted(service.serviceTime) }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold">
          Tiempo estimado
        </div>
        <div>{{ timeFormatted(service.estimatedTime) }}</div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { minutesToHours } from "@zubut/common/src/utils/time";
import { formatMoney } from "@/utils/money";
import serviceRate from "@/mixins/serviceRate";
import ServiceStatus from "@/constants/services/status";
import AdditionalHours from "@zubut/common/src/constants/services/additional-hours";

export default {
  name: "MultiPointServicePaymentDetail",

  mixins: [serviceRate],

  props: {
    service: {
      type: Object,
      required: true
    },
    serviceBrand: {
      type: String,
      default: ""
    },
    serviceCard: {
      type: String,
      default: ""
    },
    serviceWalletId: {
      type: String,
      default: ""
    },
    currency: {
      type: String,
      default: "MXN"
    }
  },

  data() {
    return {};
  },

  computed: {
    canceledService() {
      return ServiceStatus.CANCELLED_STATUS.includes(this.service.status);
    },

    chargeForService() {
      return this.shouldChargeForCanceledService(this.service);
    },

    messageForSubtotal() {
      if (this.canceledService) {
        return this.chargeForService
          ? "Comisión por servicio cancelado"
          : "Servicio cancelado";
      }
      return "Subtotal";
    },

    destinations() {
      if (this.service.destinations) {
        return this.service.destinations;
      }
      return [];
    },

    isOld() {
      if (this.service.vehicleType === undefined) {
        return true;
      }
      return false;
    },

    showCreditCardTemination() {
      if (
        this.serviceBrand !== null &&
        this.serviceBrand !== "none" &&
        !(
          this.service.status === ServiceStatus.NUM_DRIVER_CANCELLED ||
          this.noChargeForCanceledService
        )
      ) {
        return true;
      }
      return false;
    },

    total() {
      return this.service?.paymentDetails?.total ?? null;
    },

    distanceFormatted() {
      return this.service.km === 1
        ? `${this.service.km} km`
        : `${this.service.km} kms`;
    },

    totalFormatted() {
      if (this.total !== null) {
        return formatMoney(this.total).replace(/.00$/, "");
      }
      return "";
    },

    subtotal() {
      return this.service?.paymentDetails?.subtotal ?? null;
    },

    subtTotalFormatted() {
      if (this.subtotal !== null) {
        return formatMoney(this.subtotal);
      }
      return "";
    },

    tax() {
      return this.service?.paymentDetails?.tax ?? null;
    },

    taxFormatted() {
      if (this.subtotal !== null) {
        return formatMoney(this.tax);
      }
      return "";
    },

    haveAdditionalHours() {
      return (
        this.service.additionalHoursRequest &&
        this.service.additionalHoursRequest.status ===
          AdditionalHours.NUM_ACCEPTED_HOURS
      );
    },

    getAdditionalHoursCost() {
      return (
        formatMoney(this.service.additionalHoursRequest?.additionalCost) || null
      );
    },

    taxMessage() {
      if (this.currency.toUpperCase() === "MXN") {
        return "IVA";
      }
      return "Cargo por servicio";
    }
  },

  methods: {
    timeFormatted(timeWithoutFormatting) {
      const time = Math.round(timeWithoutFormatting ?? 0);
      if (time > 60) {
        return `${minutesToHours(time)} hrs`;
      }
      return `${time} mins`;
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-normal-detail {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;
  font-size: 12px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0;
  }

  .resume-data {
    color: $dim-gray;
  }
}
</style>
