<template>
  <b-row
    class="d-flex flex-row align-items-center row-container"
    @click="onClick"
  >
    <div class="icon-container">
      <slot name="icon"></slot>
    </div>
    <amex-icon v-if="icon === 'american_express'" id="image" />
    <mc-icon v-else-if="icon === 'mc'" id="image" />
    <visa-icon v-else-if="icon === 'visa'" id="image" />
    <img
      v-else-if="icon === 'wallet'"
      id="image"
      src="@/assets/img/zubutwallet.png"
    />
    <b-col id="payment-info">
      <p class="noselect">
        {{ title }} <br />
        <span id="subtitle">{{ subtitle }}</span>
      </p>
    </b-col>
    <slot name="dropdown"></slot>
  </b-row>
</template>

<script>
import AmexIcon from "@/assets/icons/ic_amex.svg";
import McIcon from "@/assets/icons/ic_mastercard.svg";
import VisaIcon from "@/assets/icons/ic_visa.svg";

export default {
  name: "ZPaymentItem",

  components: {
    AmexIcon,
    McIcon,
    VisaIcon
  },

  props: {
    onClick: {
      type: Function,
      default: function() {}
    },
    icon: {
      type: String,
      validator: val =>
        ["american_express", "mc", "visa", "wallet"].indexOf(val) !== -1,
      default: ""
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
#payment-info {
  min-width: 95px;
}
#image {
  width: 38px;
  height: 38px;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;

  #subtitle {
    color: $suva-grey;
    font-weight: 350;
  }
}
.row-container {
  margin: 0 !important;
}

.icon-container {
  width: 30px;
}
</style>
