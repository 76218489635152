<template>
  <div class="service-logs">
    <div class="title font-weight-bold h6 mb-3">
      Registros de viaje
    </div>
    <div>
      <package-service-logs
        v-if="service.type === serviceType.NUM_PACKAGE_DELIVERY"
        :service="service"
      />
      <multi-point-service-logs
        v-else
        :service="service"
        @cancel-schedule-service="$emit('cancel-schedule-service')"
        @update:show="$emit('update:show', $event)"
      />
    </div>
  </div>
</template>

<script>
import MultiPointServiceLogs from "./MultiPointServiceLogs";
import PackageServiceLogs from "./PackageServiceLogs";
import ServiceType from "@zubut/common/src/constants/services/type";

export default {
  name: "ServiceLogs",

  components: {
    MultiPointServiceLogs,
    PackageServiceLogs
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      serviceType: ServiceType
    };
  }
};
</script>
