









































import Vue from "vue";

export default Vue.extend({
  name: "ServiceDetailTabsAdditionalOptions",

  props: {
    additionalOptions: {
      type: Object,
      default: () => ({
        driverReturn: false,
        signature: false
      })
    },
    additionalHoursRequest: {
      type: Object,
      default: () => ({
        additionalCost: 0,
        additionalHours: 0,
        requestProof: {
          signature: "",
          personalId: "",
          requestorName: ""
        },
        status: 0
      })
    }
  },

  computed: {
    requestorName(): string {
      return this.additionalHoursRequest?.requestProof?.requestorName || "";
    },
    additionalHours(): number {
      return this.additionalHoursRequest?.additionalHours || "";
    },
    signature(): string {
      const signature = this.additionalHoursRequest?.requestProof?.signature;
      if (!signature) return "";
      if (signature.startsWith("data:image")) {
        return signature;
      }
      return "data:image/jpeg;base64," + signature;
    }
  }
});
