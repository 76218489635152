<template>
  <div>
    <div class="d-flex justify-content-between mt-4">
      <div class="d-flex align-items-center">
        <p class="incidence">
          {{ incidenceMessage }}
        </p>
        <font-awesome-icon
          v-if="hasPosition"
          v-tooltip.top="'Ver en mapa'"
          class="action ml-2"
          icon="external-link-alt"
          size="sm"
          @click="$root.$emit('open-incidence', incidence.position)"
        />
      </div>
      <b-spinner v-show="loading" small variant="primary" type="grow" />
      <a
        v-if="isValidatableIncidence"
        v-show="!loading"
        class="action"
        @click.stop="validateIncidence(incidence.id)"
      >
        Aceptar
      </a>
    </div>
    <p class="time">
      {{ incidence.time }}
    </p>
    <hr />
  </div>
</template>

<script>
import IncidencesConstants from "@/constants/services/incidences";
import IncidencesDestination from "@/constants/services/incidences-destination";
import IncidencesReturn from "@/constants/services/incidences-return";
import IncidencesClient from "@/constants/services/incidences-client";
import IncidencesDriver from "@/constants/services/incidences-driver";
import IncidencesService from "@/constants/services/incidences-service";
import IncidencesCollection from "@zubut/common/src/constants/services/incidences-collection";

export default {
  name: "IncidenceItem",

  props: {
    incidence: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    incidenceCategory() {
      switch (this.incidence.incidenceId) {
        case IncidencesConstants.NUM_INCIDENCE_DESTINATION:
          return IncidencesDestination.get;
        case IncidencesConstants.NUM_INCIDENCE_CLIENT:
          return IncidencesClient.get;
        case IncidencesConstants.NUM_INCIDENCE_RETURN:
          return IncidencesReturn.get;
        case IncidencesConstants.NUM_INCIDENCE_RETURN_BY_DRIVER:
          return IncidencesDriver.get;
        case IncidencesConstants.NUM_INCIDENCE_SERVICES:
          return IncidencesService.get;
        case IncidencesConstants.NUM_INCIDENCE_COLLECTION:
          return IncidencesCollection.get;
        default:
          return IncidencesDestination.get;
      }
    },

    isValidatableIncidence() {
      return (
        this.incidence.incidenceId ===
          IncidencesConstants.NUM_INCIDENCE_DESTINATION &&
        !this.incidence.accepted
      );
    },

    hasPosition() {
      return this.incidence.position != null;
    },

    incidenceMessage() {
      const categoryId = this.incidence.categoryId;
      return this.incidenceCategory[categoryId];
    }
  },

  methods: {
    sendMessageOpenIncidence() {
      this.$root.$emit("open-incidence", incidence.position);
    },
    validateIncidence(incidenceId) {
      this.loading = true;
      this.$store
        .dispatch("incidences/validate", incidenceId)
        .then(res => {
          this.incidence.accepted = true;
        })
        .catch(err => {
          this.$emit("validate-error");
        })
        .finally(() => {
          this.$emit("hide-incidence");
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}

hr {
  margin-bottom: 0;
}

.incidence {
  font-size: 12px;
}

.time {
  color: $dark-gray;
}

.action {
  cursor: pointer;
  color: $zubut-blue;

  &:hover {
    text-decoration: underline;
  }
}
</style>
