<template>
  <div class="border rounded p-4">
    <div class="title font-weight-bold h6 mb-3">
      Evidencias
    </div>
    <b-row
      v-for="(proof, index) in deliveryProof"
      :key="`${index}-${proof.receiverName}`"
      class="proof-card py-3"
    >
      <b-col>
        <div class="section-proof font-weight-semi-bold">
          Evidencia en {{ proof.destinationName }}
        </div>
        <div class="section-proof">
          Recibió: {{ getReceiverName(proof.receiverType) }}<br />
          Nombre: {{ proof.receiverName }}
        </div>
        <img
          v-if="proof.imageUrl"
          :src="proof.imageUrl"
          class="deliveryProof-image mt-3"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DestinationReceiver from "@/constants/services/destination-receiver";

export default {
  name: "ServiceDetailTabsDeliveryProof",

  props: {
    deliveryProof: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    getReceiverName(key) {
      return DestinationReceiver.types.find(t => t.key === key).name;
    }
  }
};
</script>

<style lang="scss" scoped>
.proof-card {
  border: 1px solid rgba($black, 0.125);
  border-right-width: 0;
  border-left-width: 0;
  border-top-width: 0;
  margin-right: 0px;
  margin-left: 0px;

  &:last-child {
    border-bottom-width: 0;
    padding-bottom: 0rem !important;
  }

  .col {
    padding-right: 0px;
    padding-left: 0px;
  }

  .section-proof {
    color: $black;
    font-size: 13px;
  }

  .deliveryProof-image {
    display: block;
    width: 300px;
    margin: 0 auto;
  }

  @media print {
    .deliveryProof-image {
      display: none;
    }
  }
}
</style>
