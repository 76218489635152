<template>
  <div v-if="hasRatings" class="resume-review border rounded p-4">
    <div class="h6 font-weight-bold">Reseñas</div>
    <div v-if="hasDriverRating" class="rating-row">
      <div class="flex-grow-1">
        <p class="mb-0">Mensajero {{ driverRating.name }}:</p>
        <p class="mb-0">{{ driverRating.comment }}</p>
      </div>
      <div class="rating flex-grow-1 text-right">
        <span>{{ getRatingIcon(driverRating.rate) }}</span>
      </div>
    </div>
    <div v-if="hasUserRating" class="rating-row">
      <div class="flex-grow-1">
        <p class="mb-0">Usuario {{ userRating.name }}:</p>
        <p class="mb-0">{{ userRating.comment }}</p>
      </div>
      <div class="rating flex-grow-1 text-right">
        <span>{{ getRatingIcon(userRating.rate) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import RatingsRate from "@/constants/ratings/rate";

export default {
  name: "ResumeReview",

  props: {
    ratings: {
      type: Object,
      default: null
    }
  },

  computed: {
    hasRatings() {
      return this.hasDriverRating || this.hasUserRating;
    },

    hasDriverRating() {
      return this.ratings && this.ratings.driver;
    },

    hasUserRating() {
      return this.ratings && this.ratings.client;
    },

    driverRating() {
      return this.ratings.driver;
    },

    userRating() {
      return this.ratings.client;
    }
  },

  methods: {
    getRatingIcon(rate) {
      switch (rate) {
        case RatingsRate.NUM_LIKE:
          return "❤️";
        case RatingsRate.NUM_DISLIKE:
          return "😕";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.resume-review {
  font-size: 12px;

  .rating-row {
    padding: 1rem 1rem 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (max-width: 768px) {
    .rating-row {
      padding: 1rem 0rem;
    }
  }
}
</style>
