<template>
  <div class="border rounded p-4 h-100">
    <div class="title font-weight-bold h6 mb-3">
      Identificadores externos
    </div>
    <b-list-group flush class="z-service-external">
      <b-list-group-item v-if="numericId">
        <div>ID Numérico</div>
        <div>
          {{ numericId }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="externalId">
        <div>
          ID Externo
        </div>
        <div>
          {{ externalId }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="externalOrderId">
        <div>
          ID de Orden
        </div>
        <div>
          {{ externalOrderId }}
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: "ServiceDetailTabsExternal",

  props: {
    numericId: {
      type: Number,
      default: null
    },

    additional: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    externalId() {
      return this.additional.externalId || null;
    },

    externalOrderId() {
      return this.additional.externalOrderId || null;
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-external {
  font-size: 13px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
  }

  .disabled {
    color: $raven;
  }
}

@media only screen and (max-width: 768px) {
  .z-service-external {
    font-size: 12px;

    .list-group-item {
      padding: 1rem 0rem;
    }
  }
}
</style>
