<template>
  <div>
    <b-row class="z-service-payment-detail-header">
      <b-col cols="4" md="4" class="d-flex justify-content-between">
        <span class="title font-weight-bold h6">
          Desglose de tarifa
        </span>
      </b-col>
      <b-col cols="8" md="8" class="text-right mb-3">
        <div>
          <status-payment class="ml-auto" :status="paymentStatus" />
          <div class="mt-1">
            <div class="small mt-2">
              {{ paymentStatusMessage }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StatusPayment from "@/app/components/StatusPayment";
import ServicePaymentStatus from "@/constants/services/payment-status";
import ServiceStatus from "@/constants/services/status";
import ServiceBillingStatus from "@/constants/services/billing-status";

export default {
  name: "ServicePaymentDetailHeader",

  components: {
    StatusPayment
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    isPackageService() {
      return this.service.additional && this.service.additional.deliveries;
    },

    isPayedWithWallet() {
      return this.service.walletId != null;
    },

    paymentStatus() {
      return this.service?.paymentStatus ?? null;
    },

    paymentStatusMessage() {
      if (
        this.service.status === ServiceStatus.NUM_FINISHED &&
        this.paymentStatus === ServicePaymentStatus.NUM_PAID &&
        !this.isPayedWithCreditCard &&
        !this.isPayedWithWallet
      ) {
        return "Cargo añadido a tu cuenta";
      } else if (
        this.service.status === ServiceStatus.NUM_FINISHED &&
        this.paymentStatus === ServicePaymentStatus.NUM_NOT_PAID
      ) {
        return "El cargo fue rechazado";
      } else if (
        this.service.billingStatus === ServiceBillingStatus.NUM_INVOICED
      ) {
        return "Cargo y factura generados con éxito";
      } else if (
        this.paymentStatus === ServicePaymentStatus.NUM_PAID ||
        this.chargeForCanceledService
      ) {
        return "Se realizó correctamente el cargo";
      } else if (this.noChargeForCanceledService) {
        return "No se realizó ningún cargo a tu tarjeta";
      }
      return "";
    },

    serviceType() {
      if (this.isPackageService) {
        return "package";
      }
      return "other";
    },

    isPayedWithCreditCard() {
      return this.service.cardInfo != null;
    },

    creditCardBrand() {
      const brand = this.service?.cardInfo?.brand ?? "";
      return brand.toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-payment-detail-header {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  a {
    color: $zubut-blue !important;
  }
}
</style>
