<template>
  <b-modal
    ref="selectDriverModal"
    class="assign-driver-dialog"
    title="Seleccione un mensajero"
    @hidden="hide"
  >
    <z-dropdown-drivers
      v-model="selectedDriver"
      placeholder="Seleccione un mensajero"
      search
      variant="white"
    />
    <template v-slot:modal-footer>
      <div>
        <z-button
          variant="primary"
          :disabled="selectedDriver === null"
          @click="$emit('assign-driver', selectedDriver)"
        >
          Asignar
        </z-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ZDropdownDrivers from "@/app/components/ZDropdownDrivers";

export default {
  name: "AssignDriverDialog",

  components: {
    ZDropdownDrivers
  },

  props: {
    drivers: {
      type: Array,
      required: true,
      default: () => []
    },
    driver: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      searchDrivers: this.drivers,
      selectedDriver: this.driver
    };
  },

  watch: {
    driver: {
      handler(newVal) {
        this.selectedDriver = newVal;
      },
      immediate: true
    }
  },

  methods: {
    hide() {
      this.selectedDriver = null;
      this.$refs.selectDriverModal.hide();
    },
    show() {
      this.selectedDriver = this.driver;
      this.$refs.selectDriverModal.show();
    }
  }
};
</script>
