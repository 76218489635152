<template>
  <div class="share-service-modal">
    <b-modal
      :visible="show"
      centered
      @change="toggleModal"
      @shown="openedModal"
    >
      <template v-slot:modal-header>
        <div>
          <p class="mb-0">Enlace para compartir servicio</p>
        </div>
      </template>
      <loading-spinner v-show="loading" />
      <div v-show="!loading" class="px-3">
        <quick-message
          class="mb-2"
          :show="hasError"
          message="Ocurrió un error al general la URL"
          type="error"
        />
        <div v-show="!hasError" class="tracking-url">
          <span>Todos los que tengan esta URL pueden ver el servicio</span>
          <p
            v-tooltip="{
              content: 'Copiar',
              placement: 'top-center'
            }"
            class="url-link mt-4"
            @click="copyToClipboard"
          >
            {{ trackingUrl }}
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div>
          <z-button variant="primary" @click="toggleModal(false)">
            Aceptar
          </z-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Link from "@/utils/link";
import Clipboard from "@/utils/clipboard";
import ServiceType from "@zubut/common/src/constants/services/type";

export default {
  name: "ShareServiceModal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    serviceId: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      validator: ServiceType.validator,
      required: true
    }
  },
  data() {
    return {
      trackingUrl: "",
      loading: false,
      hasError: false
    };
  },
  methods: {
    openedModal() {
      this.getTrackingUrl();
    },
    toggleModal(show) {
      this.$emit("update:show", show);
    },
    async getTrackingUrl() {
      this.loading = true;
      this.trackingUrl = await Link.tracking(
        this.serviceId,
        false,
        ServiceType.isParcel(this.type)
      );
      await this.copyToClipboard();
      this.loading = false;
    },
    async copyToClipboard() {
      try {
        await Clipboard.copy(this.trackingUrl);
      } catch {}
    }
  }
};
</script>

<style lang="scss" scoped>
.tracking-url {
  span {
    color: rgba($black, 0.4);
  }

  .url-link {
    cursor: pointer;
    overflow-x: scroll;
    white-space: nowrap;
  }
}
</style>
