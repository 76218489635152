<template>
  <div class="border rounded p-4">
    <div class="title font-weight-bold h6 mb-3">
      Firmas
    </div>
    <b-row
      v-for="(signature, index) in signatures"
      :key="`${index}-${signature.id}`"
      class="singatures-card py-3"
    >
      <b-col>
        <div class="section-title">
          Firma en {{ signature.name }}, el {{ signature.time }}
        </div>
      </b-col>
      <b-col class="d-flex justify-content-end py-2">
        <img :src="'data:image/jpeg;base64,' + signature.image" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ServiceDetailTabsSignatures",

  props: {
    signatures: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.singatures-card {
  border: 1px solid rgba($black, 0.125);
  border-right-width: 0;
  border-left-width: 0;
  border-top-width: 0;
  margin-right: 0px;
  margin-left: 0px;

  &:last-child {
    border-bottom-width: 0;
  }

  .col {
    padding-right: 0px;
    padding-left: 0px;
  }

  .section-title {
    color: $black;
    font-size: 13px;
  }
}
</style>
