<template>
  <div class="service-payment-detail-invoice">
    <div v-if="isBilledService">
      <div>
        <z-button
          :loading="downloadingInvoice"
          :disabled="downloadingInvoice"
          @click="downloadInvoice"
        >
          Descargar factura
        </z-button>
      </div>
    </div>
    <z-button
      v-if="showGenerateInvoice"
      id="generate-invoice"
      :loading="generatingInvoice"
      :disabled="generatingInvoice"
      @click="createInvoice"
    >
      Generar Factura
    </z-button>
    <div
      v-if="hasInvoiceError"
      id="invoice-error-message"
      class="d-inline-block small ml-3"
    >
      Ocurrió un error al intentar facturar
    </div>
  </div>
</template>

<script>
import Invoices from "@/services/invoices";
import Transactions from "@/services/transactions";
import ServiceBillingStatus from "@/constants/services/billing-status";
import ServicePaymentStatus from "@/constants/services/payment-status";
import ServiceStatus from "@/constants/services/status";
import ServiceType from "@zubut/common/src/constants/services/type";
import notifyMixin from "@/mixins/notify";
import { isThisMonth, parseISO } from "date-fns";

export default {
  name: "ServicePaymentDetailInvoice",

  mixins: [notifyMixin("notifications")],

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      downloadingInvoice: false,
      generatingInvoice: false
    };
  },

  computed: {
    isPayedService() {
      return this.service.paymentStatus === ServicePaymentStatus.NUM_PAID;
    },

    isPayedWithWallet() {
      return this.service.walletId != null;
    },

    isBilledService() {
      return this.service.billingStatus === ServiceBillingStatus.NUM_INVOICED;
    },

    isFinishedService() {
      return this.service.status === ServiceStatus.NUM_FINISHED;
    },

    hasInvoiceError() {
      return (
        this.service.billingStatus === ServiceBillingStatus.NUM_INVOICE_ERROR
      );
    },

    showGenerateInvoice() {
      if (
        this.isFinishedService &&
        !this.isBilledService &&
        this.isPayedService &&
        !this.isPayedWithWallet &&
        ServiceType.isMultiPoint(this.service.type)
      ) {
        const createdInCurrentMonth = isThisMonth(
          parseISO(this.service.createdAt),
          "d 'de' MMM. 'de' yyyy h:mm a"
        );
        return createdInCurrentMonth;
      }
      return false;
    }
  },

  methods: {
    createInvoice() {
      this.generatingInvoice = true;
      Transactions.createInvoice(this.service.transactionId)
        .then(() => {
          this.notify({
            type: "success",
            text: "Servicio facturado exitosamente"
          });
          this.$emit("invoice-created");
        })
        .catch(err => {
          this.$captureError(err);
          console.error(err);
          this.notify({
            type: "error",
            title: "Error al facturar el servicio",
            text: "Contacta a soporte help@zubut.com"
          });
          this.$emit("invoice-error");
        })
        .finally(() => {
          this.generatingInvoice = false;
        });
    },

    downloadInvoice() {
      this.downloadingInvoice = true;
      Invoices.download({
        satWsId: this.service.invoice.satWsId,
        formats: ["pdf", "xml"]
      })
        .then(response => {
          const blob = new Blob([response]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `servicio-${this.service.id}.zip`;
          link.click();
          this.notify({
            type: "success",
            title: "Factura descargada"
          });
        })
        .catch(err => {
          this.$captureError(err);
          console.error(err);
          this.notify({
            type: "error",
            title: "Error de dercaga de factura"
          });
        })
        .finally(() => {
          this.downloadingInvoice = false;
        });
    }
  }
};
</script>
