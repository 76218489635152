<template>
  <div>
    <div>
      <service-payment-detail
        :is-billed-service="isBilledService"
        :service="service"
        :service-brand="service.brand"
        :service-card="service.card"
        :service-wallet-id="service.walletId"
        :service-city="service.city"
        @reload="$emit('reload')"
      />
    </div>

    <resume-review class="mt-4" :ratings="service.ratings" />
  </div>
</template>

<script>
import ResumeReview from "./ResumeReview";
import ServiceBillingStatus from "@/constants/services/billing-status";
import ServicePaymentDetail from "@/app/components/ServicePaymentDetail/ServicePaymentDetail";

export default {
  name: "ServiceDetailTabsResume",

  components: {
    ResumeReview,
    ServicePaymentDetail
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  computed: {
    isBilledService() {
      return this.service.billingStatus === ServiceBillingStatus.NUM_INVOICED;
    }
  }
};
</script>
