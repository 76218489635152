<template>
  <div>
    <div class="service-detail ">
      <z-responsive-modal
        :show.sync="showModal"
        :centered="false"
        size="xl"
        :hide-close="true"
        body-class="p-4 service-detail-modal-body"
        @hide="handleClose"
      >
        <div v-if="loading">
          <loading-spinner />
        </div>
        <div v-else>
          <b-row>
            <b-col cols="12" sm="12" md="6">
              <go-back
                v-if="!isDesktop()"
                title=""
                class="mb-3"
                @back-action="$router.go(-1)"
              />
              <div class="d-flex align-items-center">
                <service-type-avatar
                  :type="service.type"
                  size="lg"
                  class="mr-3"
                />
                <div class="card-title m-0">{{ title }}</div>
              </div>
              <status-service
                class="mt-3"
                :status="service.status"
                :cancel-reason="service.cancelReason"
              />
            </b-col>
            <b-col sm="12" md="6">
              <buttons-layout>
                <activity-button :id="serviceId" type="service" />
                <z-button
                  size="md"
                  variant="secondary"
                  fit="auto"
                  @click="print"
                >
                  <font-awesome-icon icon="print" size="lg" />
                </z-button>
                <z-button
                  v-if="showShareService"
                  size="md"
                  variant="primary"
                  @click="toggleShareServiceModal(true)"
                >
                  Compartir viaje
                </z-button>
                <z-button
                  v-if="
                    isCancelled &&
                      $can(
                        servicesAccess.REASSIGN_SERVICE,
                        servicesAccess.moduleName
                      )
                  "
                  size="md"
                  variant="primary"
                  fit="auto"
                  @click="openAssignModal"
                >
                  Reactivar
                </z-button>
                <z-button
                  v-if="isPayable"
                  size="md"
                  variant="primary"
                  @click="showChargeModal = true"
                >
                  Cobrar
                </z-button>
              </buttons-layout>
            </b-col>
          </b-row>
          <quick-message
            class="mt-3 w-100"
            with-icon
            :show="isCheckInLate"
            message="Check in de la reservación fue realizado tarde. Puedes verificar los datos en la pestaña de Registro de viaje."
          />
          <quick-message
            class="mt-3 w-100"
            with-icon
            type="error"
            :show="hasError"
            :message="errorMessage"
            @update:show="hasError = $event"
          />
          <b-row class="pb-4 mt-3">
            <b-col md="6" class="px-3">
              <delivery-detail-card
                class="h-100"
                :type="service.type"
                :delivery="service"
              />
            </b-col>
            <b-col md="6" class="px-3 mt-4 mt-md-0">
              <loading-spinner v-show="loading" />
              <service-detail-tabs
                v-show="!loading"
                class="border rounded p-4 h-100"
                :service="service"
                @show-service-modal="showCancelServiceModal"
                @reload="getService"
                @update:show="showErrorMessage($event)"
              />
            </b-col>
          </b-row>
          <confirmation-dialog
            :show="showCancelModal"
            @accept="cancelService"
            @cancel="hideCancelServiceModal"
          >
            ¿Seguro que deseas cancelar el servicio?
          </confirmation-dialog>
          <share-service-modal
            :show.sync="showShareModal"
            :service-id="service.id"
            :type="service.type"
          />
          <charge-transaction-modal
            :show.sync="showChargeModal"
            :service="service"
            :client="service.client"
            :amount="total"
            :subtotal="subtotal"
            :tax="tax"
            @update-listing="getService"
          />
        </div>
      </z-responsive-modal>
    </div>
    <assign-driver-dialog
      ref="assignDriver"
      :driver="selectedDriver"
      :drivers="drivers"
      @assign-driver="reactivate"
    />
  </div>
</template>

<script>
import { differenceInHours, parseISO } from "date-fns";
import { format } from "@zubut/common/src/utils/time";
import notifyMixin from "@/mixins/notify";
import ScreensMixin from "@/mixins/screen";
import ActivityButton from "@/app/components/ActivityButton.vue";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import StatusService from "@zubut/common/src/components/StatusService";
import AssignDriverDialog from "@/app/components/AssignDriverDialog";
import Services from "@/services/services";
import ServicesAccess from "@/constants/access/services";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import ServiceBillingStatus from "@/constants/services/billing-status";
import ServiceStatus from "@/constants/services/status";
import ServicePaymentStatus from "@/constants/services/payment-status";
import WarehouseAccess from "@/constants/access/warehouses";
import { makeShortUUID } from "@/utils/strings";
import ServiceDetailTabs from "@/app/components/ServiceDetailTabs/ServiceDetailTabs";
import ShareServiceModal from "./ShareServiceModal";
import GoBack from "@zubut/common/src/components/GoBackTitle";
import ChargeTransactionModal from "@/app/components/ChargeTransactionModal";
import Http from "@zubut/common/src/constants/http";
import DeliveryDetailCard from "../DeliveryDetail/DeliveryDetailCard.vue";
import ButtonsLayout from "@/app/layouts/ButtonsLayout.vue";
import ZResponsiveModal from "@zubut/common/src/components/ZResponsiveModal";

export default {
  name: "ServiceDetail",

  components: {
    ActivityButton,
    AssignDriverDialog,
    ServiceDetailTabs,
    ServiceTypeAvatar,
    ShareServiceModal,
    ConfirmationDialog,
    StatusService,
    ChargeTransactionModal,
    GoBack,
    DeliveryDetailCard,
    ButtonsLayout,
    ZResponsiveModal
  },

  mixins: [notifyMixin("notifications"), ScreensMixin],

  data() {
    return {
      servicesAccess: ServicesAccess,
      loading: true,
      showShareModal: false,
      showCancelModal: false,
      showChargeModal: false,
      showAssignModal: false,
      showModal: false,
      service: null,
      selectedDriver: null,
      hasError: false,
      errorMessage: ""
    };
  },

  computed: {
    clientDisplayName() {
      return this.clientContact ?? this.clientName;
    },

    clientName() {
      return this.service.client?.name;
    },

    clientContact() {
      return this.service.client?.contact;
    },

    clientPhone() {
      return this.service.client?.phone;
    },

    createdAtDate() {
      if (this.service?.createdAt) {
        return format(
          parseISO(this.service.createdAt),
          "d 'de' MMM. 'de' yyyy h:mm a"
        );
      }
      return "";
    },

    title() {
      return `Detalle del servicio ID ${this.shortServiceId}`;
    },

    destinations() {
      return this.service?.destinations || [];
    },

    isPackageService() {
      return this.service?.type === ServiceType.NUM_PACKAGE_DELIVERY;
    },

    isBilledService() {
      return this.service?.billingStatus === ServiceBillingStatus.NUM_INVOICED;
    },

    showShareService() {
      return !ServiceType.isParcel(this.service?.type);
    },

    isPayable() {
      return (
        (this.service?.status === ServiceStatus.NUM_FINISHED ||
          (ServiceStatus.CANCELLED_STATUS.includes(this.service?.status) &&
            this.service?.paymentDetails?.subtotal > 0)) &&
        this.service?.paymentStatus === ServicePaymentStatus.NUM_NOT_PAID
      );
    },

    isCancelled() {
      return (
        ServiceStatus.CANCELLED_STATUS.includes(this.service?.status) &&
        (this.service?.paymentStatus === ServicePaymentStatus.NUM_NOT_PAID ||
          this.service?.estimatedRate === 0)
      );
    },

    total() {
      return this.service?.paymentDetails?.total ?? 0;
    },

    subtotal() {
      return this.service?.paymentDetails?.subtotal ?? 0;
    },

    tax() {
      return this.service?.paymentDetails?.tax ?? 0;
    },

    serviceId() {
      return this.$route.params.id;
    },
    shortServiceId() {
      return makeShortUUID(this.serviceId);
    },

    isCheckInLate() {
      let start = this.service?.reservationBlock?.start ?? null;
      let checkIn = this.service?.resume?.checkIn ?? null;
      if (start == null || checkIn == null) {
        return false;
      }
      start = parseISO(start);
      checkIn = parseISO(checkIn);
      const hours = differenceInHours(checkIn, start);
      return hours >= 1;
    },

    drivers() {
      return this.$store.getters["drivers/activeDrivers"];
    }
  },

  mounted() {
    this.getService();
    this.getActiveDrivers();
  },

  methods: {
    goBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push({ name: "services" });
    },

    handleClose() {
      this.$router.go(-1);
      this.showModal = false;
    },

    print() {
      setTimeout(() => {
        window.print();
      }, 100);
    },

    parseDate(date, formatStr) {
      return format(parseISO(date), formatStr);
    },
    showErrorMessage(event) {
      this.hasError = event.hasError;
      this.errorMessage = event.errorMessage;
    },

    getActiveDrivers() {
      if (this.drivers.length === 0) {
        this.$store.dispatch("drivers/getAllDrivers");
      }
    },

    openAssignModal() {
      this.showAssignModal = true;
      this.selectedDriver = this.drivers.find(
        _ => _.id === this.service?.driverId
      );
      this.$refs.assignDriver.show();
    },

    reactivate(driverId) {
      this.showAssignModal = false;
      this.$refs.assignDriver.hide();
      const data = {
        serviceId: this.service.id,
        driverId: driverId
      };
      const successText = "Servicio reasignado";
      this.$store
        .dispatch("services/reactivateService", data)
        .then(() => {
          this.getService();
          this.notify({
            type: "success",
            text: successText
          });
        })
        .catch(err => {
          this.$captureError(err);
          this.notify({
            type: "error",
            text: err.message || "Error al intentar reactivar servicio"
          });
        });
    },

    getService() {
      this.loading = true;
      Services.getDetails(this.serviceId)
        .then(({ service }) => {
          if (service.incidences) {
            service.incidences.forEach(incidence => {
              incidence.time = this.parseDate(incidence.createdAt, "h:mm a");
              const order = incidence.order;
              //the order does not guarantee that it is the correct destination
              if (order != null && order < service.destinations.length) {
                (service.destinations[order].incidences =
                  service.destinations[order].incidences || []).push(incidence);
              }
            });
          }
          this.service = service;

          // this.$store.commit("services/setSelectedService", service);
          this.loading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.loading = false;
          }
        });
    },

    toggleShareServiceModal(show) {
      this.showShareModal = show;
    },

    showCancelServiceModal() {
      this.showCancelModal = true;
    },

    hideCancelServiceModal() {
      this.showCancelModal = false;
    },

    cancelService() {
      this.hideCancelServiceModal();
      const cancel = {
        id: this.service?.id,
        clientId: this.service?.clientId
      };
      this.$store
        .dispatch("services/cancel", cancel)
        .then(() => {
          this.getService();
        })
        .catch(err => {
          this.$captureError(err);
          console.log(err);
        });
    },

    goToWarehouse() {
      if (
        this.$can(WarehouseAccess.READ_WAREHOUSES, WarehouseAccess.moduleName)
      ) {
        this.$router.push({
          name: "warehouseDetail",
          params: { id: this.service.warehouse.id },
          query: {
            tab: "guias"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.service-detail-modal-body {
  min-height: 94vh;
}

.service-detail {
  .created-date {
    font-size: 12px;
  }
}

@media print {
  .service-detail {
    ul :nth-child(2),
    ul :nth-child(3),
    ul :nth-child(4),
    ul :nth-child(5) {
      display: none !important;
    }
    .menu {
      display: none !important;
    }
    .signature {
      margin-top: 150%;
      display: block;
    }
  }
}
</style>
