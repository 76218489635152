<template>
  <div class="z-service-incidences-detail border rounded p-4">
    <div class="title font-weight-bold h6 mb-3">
      Incidencias
    </div>
    <quick-message
      class="my-3"
      with-icon
      show
      message="Puedes validar las incidencias de destino desde la sección de destinos"
    />
    <quick-message
      class="my-3"
      with-icon
      :show.sync="hasErrors"
      :message="message"
      type="error"
    />
    <b-list-group flush>
      <div
        v-for="(incidence, index) in incidences"
        :key="`incidence-${index}`"
        class="d-flex flex-column align-items-stretch"
      >
        <incidence-item
          :incidence="incidence"
          @validate-error="hasErrors = true"
        />
      </div>
    </b-list-group>
  </div>
</template>

<script>
import IncidenceConstants from "@/constants/services/incidences";
import IncidenceItem from "@/app/components/IncidenceItem";

export default {
  name: "ServiceIncidences",
  components: {
    IncidenceItem
  },
  props: {
    incidences: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hasErrors: false,
      message: ""
    };
  }
};
</script>

<style lang="scss" scoped>
.z-service-incidences-detail {
  font-size: 13px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
  }
}

@media only screen and (max-width: 768px) {
  .z-service-incidences-detail {
    font-size: 12px;

    .list-group-item {
      padding: 1rem 0rem;
    }
  }
}
</style>
