<template>
  <div class="z-service-payment-detail">
    <div>
      <div
        class="z-total-container d-flex align-items-center justify-content-between font-weight-semi-bold"
      >
        <div class="h5 font-weight-bold">
          Total
        </div>
        <div class="amount">-</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParcelServicePaymentDetail"
};
</script>

<style lang="scss" scoped>
.z-service-payment-detail {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;
  font-size: 12px;

  .z-total-container {
    .amount {
      font-size: 20px;
    }
  }
}
</style>
