<template>
  <div class="invoice-error rounded border p-4">
    <div class="title font-weight-bold h6 mb-3">
      Error de facturación
    </div>
    <div class="small secondary mb-2">{{ error.name }}:</div>
    <div>
      {{ error.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceDetailTabsInvoiceError",

  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
