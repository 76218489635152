<template>
  <div class="z-service-normal-logs">
    <div
      v-if="isScheduledService && !isRentPerHourService"
      class="scheduled-service-register border-bottom"
    >
      <div class="text-left">
        <div>
          Se espera el servicio
          <b>{{ scheduledServiceDate }}</b> a las
          <b>{{ scheduledServiceTime }}</b> horas.
        </div>
      </div>
      <div class="text-right">
        <div>
          <a class="cancel-scheduled" @click="$emit('cancel-schedule-service')">
            Cancelar Servicio
          </a>
        </div>
      </div>
    </div>
    <b-list-group flush>
      <b-list-group-item
        v-if="isRentPerHourService"
        class="py-3 d-flex justify-content-between"
      >
        <div>
          <div><span class="text-muted">Hora de reservación:</span></div>
          <div>
            {{ rentServiceDate }} ({{ rentServiceStart }} -
            {{ rentServiceEnd }})
          </div>
        </div>
        <div>
          <div><span class="text-muted">Duración:</span></div>
          <div class="text-right">{{ rentServiceDuration }} hrs.</div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        Servicio requerido y creado con éxito
        <div class="text-right">
          {{ creationTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="!isRentPerHourService && acceptedTime">
        <span>
          Tu viaje fue aceptado por el mensajero
        </span>
        <div>
          {{ acceptedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isRentPerHourService && checkInTime">
        <span>
          Check-in
        </span>
        <div>
          {{ checkInTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item
        v-for="(stop, index) in serviceStops"
        :key="stop.order"
        class="d-flex flex-column align-items-stretch"
      >
        <div class="d-flex flex-row justify-content-between">
          <div>El mensajero llegó a {{ stop.name }}</div>
          <div>
            {{ stop.arrived }}
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between">
          Firmado en {{ stop.name }}
          <div>
            {{ stop.delivered }}
          </div>
        </div>
        <div v-if="hasIncidences(index)">
          <div
            v-for="(incidence, incidenceIndex) in destinations[index]
              .incidences"
            :key="`incidence ${incidenceIndex}`"
            class="d-flex flex-row justify-content-between"
          >
            <div>
              <font-awesome-icon icon="incidence" class="m-auto text-gamboge" />
              {{
                getIncidenceMessage(incidence.categoryId, incidence.incidenceId)
              }}
            </div>
            <div>
              {{ incidence.time }}
            </div>
          </div>
        </div>
        <div v-if="destinations[index].parcelId">
          <div class="d-flex flex-row justify-content-between">
            <div>
              <font-awesome-icon
                v-tooltip.left="{
                  content: 'Presiona para ver evidencia'
                }"
                icon="sign-file"
                class="m-auto sign-icon"
                @click.stop="showParcelEvidence(destinations[index].parcelId)"
              />
              Descargar Evidencia
            </div>
            <div>
              {{ stop.delivered }}
            </div>
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item
        v-for="dest in destinations.filter(el => el.signed)"
        :key="dest.id"
      >
        Firma realizada por {{ dest.name }}
        <div>
          {{ dest.signed }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="!isRentPerHourService && isSuccessfulService">
        Paquete entregado y servicio exitosamente finalizado
        <div class="text-right">
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isRentPerHourService && isSuccessfulService">
        Check-out
        <div>
          {{ checkOutTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isCanceledByClientService">
        Cancelaste exitosamente tu servicio
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isCanceledByZubutService">
        Tu servicio fue cancelado por zubut
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isCanceledByDriverService">
        Tu servicio fue cancelado por el mensajero
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="isCanceledByClientService">
        Cancelaste exitosamente tu servicio
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="haveAdditionalHours">
        {{
          `Solicitó ${
            additionalHours === 1
              ? additionalHours + " hr"
              : additionalHours + " hrs"
          } extra`
        }}
        <div v-if="additionalHoursStatus === pendingHours && !statusChanged">
          <button class="btn btn-link" @click="handleDecision(true)">
            Aceptar
          </button>
          /
          <button class="btn btn-link" @click="handleDecision(false)">
            Rechazar
          </button>
        </div>
        <div v-else>
          {{ additionalHoursMessage }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="statusChanged && !decline">
        Nueva duración del servicio
        <div>
          {{ rentServiceDuration + additionalHours + " hrs" }}
        </div>
      </b-list-group-item>

      <b-list-group-item v-if="isCanceledByClientService">
        Cancelaste exitosamente tu servicio
        <div>
          {{ finishedTime }}
        </div>
      </b-list-group-item>
    </b-list-group>
    <parcel-evidence
      v-if="showParcelEvidenceModal"
      :id="`proof-btn-${proofId}`"
      :show.sync="showParcelEvidenceModal"
      :parcel-id="parcelProofId"
    />
  </div>
</template>

<script>
import ParcelEvidence from "@/app/components/ParcelEvidence";
import ReservationBlocks from "@/services/reservationBlocks";
import ServiceStatus from "@/constants/services/status";
import AdditionalHours from "@zubut/common/src/constants/services/additional-hours";
import { incidenceMessage } from "@zubut/common/src/utils/incidences";
import { formatISO, format, diffInHours } from "@zubut/common/src/utils/time";
import fromUnixTime from "date-fns/fromUnixTime";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";

export default {
  name: "MultipointServiceLogs",

  components: {
    ParcelEvidence
  },

  props: {
    service: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      additionalHours: 0,
      additionalHoursStatus: 0,
      statusChanged: false,
      decline: false,
      showParcelEvidenceModal: false,
      proofId: null
    };
  },

  computed: {
    pendingHours() {
      return AdditionalHours.NUM_PENDING_HOURS;
    },

    acceptedTime() {
      if (this.service.resume && this.service.resume.acceptedAt) {
        return formatISO(this.service.resume.acceptedAt, "h:mm a");
      }
      return "";
    },

    checkInTime() {
      if (this.service.resume && this.service.resume.checkIn) {
        return formatISO(this.service.resume.checkIn, "h:mm a");
      }
      return "";
    },

    checkOutTime() {
      if (this.service.resume && this.service.resume.finishedAt) {
        return formatISO(this.service.resume.finishedAt, "h:mm a");
      }
      return "";
    },

    checkPoints() {
      return this.service.resume.checkPoints;
    },

    creationTime() {
      return formatISO(this.service.createdAt, "d 'de' MMM 'de' yyyy, h:mm a");
    },

    destinations() {
      if (
        !ServiceStatus.CANCELLED_STATUS.includes(this.service.status) &&
        this.service.resume &&
        this.service.resume.signatures
      ) {
        const destinations = [...this.service.destinations];
        this.service.resume.signatures.forEach((signature, index) => {
          destinations[index].signed = formatISO(signature.time, "h:mm a");
        });
        return destinations;
      }
      if (this.service.destinations) return this.service.destinations;
      return [];
    },

    finishedTime() {
      let date = "";
      if (this.service.cancelledAt) {
        date = this.service.cancelledAt;
      } else if (this.service.resume && this.service.resume.finishedAt) {
        date = this.service.resume.finishedAt;
      }
      return date ? formatISO(date, "d 'de' MMM 'de' yyyy, h:mm a") : "";
    },

    isScheduledService() {
      return this.service.status === ServiceStatus.NUM_SCHEDULED;
    },

    isSuccessfulService() {
      return (
        this.finishedTime &&
        !(
          ServiceStatus.CANCELLED_STATUS.includes(this.service.status) ||
          this.service.status === ServiceStatus.NUM_REQUESTING ||
          this.service.status === ServiceStatus.NUM_ACTIVE
        )
      );
    },

    isCanceledByClientService() {
      return (
        this.finishedTime &&
        this.service.status === ServiceStatus.NUM_CLIENT_CANCELLED
      );
    },

    isCanceledByZubutService() {
      return (
        this.finishedTime &&
        this.service.status === ServiceStatus.NUM_ZUBUT_CANCELLED
      );
    },

    isCanceledByDriverService() {
      return (
        this.finishedTime &&
        this.service.status === ServiceStatus.NUM_DRIVER_CANCELLED
      );
    },

    isRentPerHourService() {
      return (
        this.service.reservationBlock &&
        this.service.reservationBlock.id.length > 0
      );
    },

    rentServiceDuration() {
      if (this.isRentPerHourService) {
        const { end, start } = this.service.reservationBlock;
        return diffInHours(start, end);
      }
      return "";
    },

    rentServiceDate() {
      if (this.isRentPerHourService) {
        return formatISO(this.service.reservationBlock.start, "MMM d, yyyy");
      }
      return "";
    },

    rentServiceEnd() {
      if (this.isRentPerHourService) {
        return formatISO(this.service.reservationBlock.end, "hh:mm a");
      }
      return "";
    },

    rentServiceStart() {
      if (this.isRentPerHourService) {
        return formatISO(this.service.reservationBlock.start, "hh:mm a");
      }
      return "";
    },

    scheduledServiceDate() {
      if (this.isScheduledService && !this.isRentPerHourService) {
        const startOfDayScheduledDate = fromUnixTime(
          this.service.additional.scheduled
        );
        let serviceDate = "";
        if (isToday(startOfDayScheduledDate)) {
          serviceDate = "Hoy";
        } else if (isTomorrow(startOfDayScheduledDate)) {
          serviceDate = "Mañana";
        } else {
          serviceDate = `el día ${format(
            startOfDayScheduledDate,
            "d 'de' MMM"
          )}`;
        }
        return serviceDate;
      }
      return "";
    },

    scheduledServiceTime() {
      return format(fromUnixTime(this.service.additional.scheduled), "HH:mm");
    },

    serviceStops() {
      if (this.service.resume && this.service.resume.checkPoints) {
        return this.service.resume.checkPoints.map(item => {
          let destination = this.destinations.find(
            d => d.id === item.destinationId
          );
          if (destination == null) {
            destination = item;
          }
          return {
            order: item.order,
            arrived: formatISO(item.arrived, "h:mm a"),
            delivered: item.delivered
              ? formatISO(item.delivered, "h:mm a")
              : "",
            name: destination.name
          };
        });
      }
      return [];
    },

    haveAdditionalHours() {
      if (this.service.additionalHoursRequest) this.getAdditionalHours();
      return this.service.additionalHoursRequest;
    },

    additionalHoursMessage() {
      return AdditionalHours.get[this.additionalHoursStatus];
    },

    parcelProofId() {
      return this.proofId;
    }
  },

  methods: {
    getAdditionalHours() {
      this.additionalHours = this.service.additionalHoursRequest.additionalHours;
      this.additionalHoursStatus = this.service.additionalHoursRequest.status;
    },

    handleDecision(decision) {
      return ReservationBlocks.reviewAdditionalHoursRequest(
        this.service.reservationBlock.id,
        decision
      )
        .then(() => {
          decision
            ? (this.additionalHoursStatus = AdditionalHours.NUM_ACCEPTED_HOURS)
            : (this.additionalHoursStatus = AdditionalHours.NUM_REJECTED_HOURS);
          if (!decision) {
            this.decline = true;
          }
          this.statusChanged = true;
        })
        .catch(err => {
          this.$emit("update:show", {
            hasError: true,
            errorMessage: err.message
          });
          this.$captureError(err);
        });
    },

    getIncidencesArray(index) {
      const incidences = this.service.incidences.filter(incidence => {
        if (incidence.order === index) {
          return incidence;
        }
      });
      return incidences;
    },

    getIncidenceMessage(categoryId, incidenceId) {
      return incidenceMessage(categoryId, incidenceId);
    },

    hasIncidences(index) {
      return this.service.destinations[index]?.incidences;
    },
    showParcelEvidence(parcelId) {
      this.proofId = parcelId;
      setTimeout(() => {
        this.showParcelEvidenceModal = true;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.z-service-normal-logs {
  font-size: 13px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
  }

  .scheduled-service-register {
    padding: 1rem 0rem;
    display: flex;
    justify-content: space-between;

    .cancel-scheduled {
      color: $alizarin;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .sign-icon:hover {
    color: $zubut-blue;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .z-service-normal-logs {
    font-size: 12px;

    .list-group-item {
      padding: 1rem 0rem;
    }

    .scheduled-service-register {
      padding: 1rem 0rem;
    }
  }
}
</style>
