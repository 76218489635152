<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import ServicePaymentStatus from "@/constants/services/payment-status";

export default {
  name: "StatusPayment",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      default: -1
    }
  },
  computed: {
    statusName() {
      if (this.status >= 0) {
        return this.getStatusName(this.status);
      } else return "";
    },
    colorVariant() {
      return this.getPaymentStatusColorVariant(this.status);
    }
  },

  methods: {
    getStatusName(status) {
      return ServicePaymentStatus.get[status];
    },

    getPaymentStatusColorVariant(paymentStatus) {
      switch (paymentStatus) {
        case ServicePaymentStatus.NUM_CREDIT:
        case ServicePaymentStatus.NUM_NOT_PAID:
        case ServicePaymentStatus.NUM_NOT_APPLICABLE:
          return "blue";
        case ServicePaymentStatus.NUM_PAID:
        case ServicePaymentStatus.NUM_REIMBURSED:
          return "green";
        default:
          return "green";
      }
    }
  }
};
</script>
